import SobreImage from "../../../Assets/sobre.png";

import "./Sobre.css";

function Sobre() {
  return (
    <div className="sobre-page">
      <img className="sobre-imagem" src={SobreImage} alt="" width={400} />
      <div className="sobre-info">
        <div className="sobre-title">
          <h1>SOBRE</h1>
        </div>
        <p>
          Valorizamos a seriedade e a imparcialidade. Estamos comprometidos em
          oferecer a todos a oportunidade justa de ganhar prêmios. Nossa equipe
          se dedica a manter a integridade dos sorteios. Junte-se a nós e
          explore como a próxima rifa pode ser a sua chance de sucesso.
          Bem-vindo à nossa plataforma de sorteios
        </p>
      </div>
    </div>
  );
}

export default Sobre;
