import React from "react";
import "./RifaNumero.css";

function RifaNumero({ numero }) {
  const numeroFormatado = String(numero).padStart(6, "0");

  return <div className="rifa-numero-container">{numeroFormatado}</div>;
}

export default RifaNumero;
