import React from "react";
import Lottie from "lottie-react";
import errorAnimation from "../../../Assets/anim/error-anim.json";
import "./ErrorPopup.css";

function ErrorPopup({ message, onClose }) {
  return (
    <div className="error-popup">
      <Lottie
        animationData={errorAnimation}
        style={{ width: "100px", height: "100px" }}
      />

      <p>{message}</p>
      <button onClick={onClose}>Fechar</button>
    </div>
  );
}

export default ErrorPopup;
