import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { format } from "date-fns";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";

import wdrifas from "../../Assets/wdrifas.jpg";
import "./LoginForm.css";
import axios from "axios";
import baseURL from "../../Contants/api_url";
import InputMask from "react-input-mask";
import { CircularProgress } from "@mui/material";
import ErrorPopup from "./Error/ErrorPopup";

const firebaseConfig = {
  apiKey: "AIzaSyC4OnQ9aAmmZ9HLoPKJ_OSyXoCmnEfEzgw",
  authDomain: "wd-rifas.firebaseapp.com",
  databaseURL: "https://wd-rifas-default-rtdb.firebaseio.com",
  projectId: "wd-rifas",
  storageBucket: "wd-rifas.appspot.com",
  messagingSenderId: "531205610562",
  appId: "1:531205610562:web:cdb0b0ec601f77743a21db",
  measurementId: "G-GCSESFD064",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

function Auth({ handleCloseLogin }) {
  const [email, setEmail] = useState("");
  const [senha, setPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [username, setUsername] = useState("");
  const [telefone, setTelefone] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPasswordError("");
    setErrorMessage("");
    if (!senha || !telefone) {
      setErrorModalOpen(true);
      setErrorMessage("Preencha todos os campos!");
      setLoading(false);
      return;
    }
    setPassword(format(new Date(senha), "dd-MM-yyyy"));
    const phoneNumber = telefone.replace(/\D/g, "");

    try {
      if (isRegistering) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          `${phoneNumber}@primosricos.com`,
          senha
        );
        const user = userCredential.user;
        const userUID = user.uid;
        await setDoc(doc(db, "users", userUID), {
          username: username,
          email: `${phoneNumber}@primosricos.com`,
          telefone: telefone,
        });
        localStorage.setItem("userUID", userUID);
        localStorage.setItem("userEmail", phoneNumber + "@primosricos.com");
        localStorage.setItem("userNumber", telefone);

        await axios.post(`${baseURL}/cadastrar-usuario`, {
          username,
          email,
          senha,
          userUID,
          telefone,
        });
      } else {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          `${phoneNumber}@primosricos.com`,
          senha
        );

        const senhaFormatada = format(
          new Date(`${senha}T00:00:00`),
          "yyyy-MM-dd"
        );

        const response = await axios.post(
          `${baseURL}/login`,
          {
            telefone,
            senha: senhaFormatada,
          },
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        );

        const { success, message, usuario } = response.data;

        if (success) {
          const { email } = usuario;

          localStorage.setItem("userEmail", email);
        } else {
          console.error("Erro ao fazer login:", message);
        }

        const user = userCredential.user;
        const userUID = user.uid;

        localStorage.setItem("userUID", userUID);

        localStorage.setItem("userNumber", phoneNumber);
      }
      handleCloseLogin();
    } catch (error) {
      setErrorModalOpen(true);

      if (error.code) {
        switch (error.code) {
          case "auth/invalid-credential":
            setErrorMessage(
              "Credenciais de login inválidas. Verifique suas informações de login."
            );
            break;
          case "auth/invalid-email":
            setErrorMessage("O email fornecido é inválido.");
            break;
          case "auth/user-not-found":
            setErrorMessage("Usuário não encontrado!");
            break;
          case "auth/wrong-password":
            setErrorMessage(
              "Credenciais inválidas. Verifique seu email e senha."
            );
            break;
          case "auth/too-many-requests":
            setErrorMessage(
              "O número de solicitações excede o máximo permitido. Aguarde alguns instantes e tente novamente!"
            );
            break;
          default:
            setErrorMessage(error.code);
        }
      } else {
        setErrorMessage(error.message || "Erro desconhecido ao autenticar.");
      }
    } finally {
      setLoading(false);
    }
  };

  const closeErrorPopup = () => {
    setErrorModalOpen(false);
  };

  return (
    <div className="form-container">
      <div className="image-container">
        <img src={wdrifas} alt="" />
      </div>
      <form onSubmit={handleAuth}>
        <h2>{isRegistering ? "REGISTRAR" : "LOGIN"}</h2>

        {isRegistering ? (
          <div className="input-area">
            <label>Nome de usuário</label>
            <input
              type="text"
              placeholder="Digite o seu nome"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
        ) : (
          <div></div>
        )}
        {isRegistering ? (
          <div className="input-area">
            <label>E-mail</label>
            <input
              type="email"
              placeholder="Digite o seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />{" "}
          </div>
        ) : (
          <div></div>
        )}
        <div className="input-area">
          <label>Telefone</label>
          <InputMask
            mask="(99) 99999-9999"
            value={telefone}
            onChange={(e) => setTelefone(e.target.value)}
            placeholder="Digite seu número"
            required
          />{" "}
        </div>

        <div className="input-area">
          <label>Data de nascimento</label>
          <input
            type="date"
            id="data-nascimento"
            placeholder="Data de nascimento"
            value={senha}
            onChange={(e) => setPassword(e.target.value)}
            required
          />{" "}
        </div>

        <button className="confirm-button" type="submit" onClick={handleAuth}>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "CONFIRMAR"
          )}
        </button>
        <p
          className="toggle-button"
          onClick={() => setIsRegistering(!isRegistering)}
        >
          {isRegistering ? "Já tem uma conta? " : "Ainda não tem uma conta? "}
          <b>
            <br />
            {isRegistering ? "Fazer login" : "Registre-se"}
          </b>
        </p>
      </form>
      {errorModalOpen && (
        <ErrorPopup message={errorMessage} onClose={closeErrorPopup} />
      )}
    </div>
  );
}

export default Auth;
