import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "./Regulamento.css";

function Regulamento() {
  // Ponto 1: Divisão de Componentes
  const Section = ({ title, items }) => (
    <div className="section">
      <h2>{title}</h2>
      <ol>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ol>
    </div>
  );

  return (
    <div className="regulamento-container">
      <h1>REGULAMENTO</h1>
      <br />

      {/* Ponto 1: Divisão de Componentes */}
      <Section
        title="COMO PARTICIPAR DA NOSSA RIFA:"
        items={[
          "Clique no sorteio em cima da imagem;",
          "Escolha o número de cotas e aperte faça login para prosseguir;",
          "Preencha o e-mail e senha e clique em confirmar;",
          "Após efetuar o login, clique em pagar e, em seguida, preencha seu CPF e clique em confirmar;",
          "Pague com PIX, copie o código do PIX abaixo do QRCODE;",
          "Abra o seu aplicativo do banco, escolha a opção PIX, copie e cole;",
          "Após efetuar o pagamento, clique em confirmar pagamento para gerar seus números;",
          "Volte à tela do site, clique no menu no canto direito, em seguida, MEUS TÍTULOS;",
          "Assim, terá acesso aos seus números recentes e de sorteios anteriores;",
        ]}
      />

      {/* Ponto 1: Divisão de Componentes */}
      <div className="section-info">
        <h2>LEIA COM ATENÇÃO, POR FAVOR</h2>
        <br />
        <h3>REGRAS:</h3>
        <ol>
          <li>
            Não aceitamos depósito via ENVELOPE, DOC, nem TED, somente PIX;
          </li>
          <li>
            Não nos responsabilizamos por PIX ou informações passadas erradas
            pelo participante;
          </li>
          <li>
            A reserva terá o tempo máximo de 10 minutos para pagamento, a cota
            não paga até o horário estipulado nos avisos perderá a reserva e
            PODERÁ ficar disponível para outro participante;
          </li>
          <li>
            Os números escolhidos serão por ordem aleatória, feita pelo sistema,
            após efetuado o pagamento, você poderá visualizar seus números logo
            abaixo da tela de pagamento ou clicando em MEUS TÍTULOS no nosso
            menu;
          </li>
        </ol>
      </div>

      {/* Ponto 1: Divisão de Componentes */}
      <Section
        title="SOBRE A PREMIAÇÃO:"
        items={[
          "Escolha o sorteio desejado e veja as premiações e regulamento do sorteio;",
          "Transporte: o produto será entregue no endereço do ganhador sem custo adicional de frete;",
        ]}
      />

      {/* Ponto 1: Divisão de Componentes */}
      <Section
        title="SOBRE O SORTEIO:"
        items={[
          "O número do ganhador será o primeiro número do segundo prêmio com os números do primeiro prêmio na sequência, de acordo com o sorteio da LOTERIA FEDERAL;",
          "A data do sorteio e o ganhador serão divulgados em nosso perfil do Instagram;",
          "Sorteio será realizado quando atingir 80% das cotas vendidas;",
          "Caso não seja vendida 100% das cotas, há a possibilidade de o número sorteado não ter sido vendido, nesse caso o sorteio fica para o próximo sorteio da Loteria Federal.",
        ]}
      />

      <b>
        <p>
          Caso haja alguma dúvida, nos chame através do WhatsApp:{" "}
          <a
            href="https://wa.link/emh3xa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
            Link do WhatsApp
          </a>
        </p>
      </b>
    </div>
  );
}

export default Regulamento;
