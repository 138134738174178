import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    signOut(auth).then(() => {
      localStorage.removeItem("userUID");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userNumber");
      navigate("/");
    });
  }, [navigate]);
}

export default Logout;
