import React from "react";
import "./SorteioCard.css";

import { Link } from "react-router-dom";

function SorteioCard({ title, imageURL, description, value, id }) {
  const routeName = `/sorteio/${id}`;
  return (
    <div className="card-sorteio">
      <img src={imageURL} alt={title} />
      <div className="card-sorteio-info">
        <h6>{title}</h6>
      </div>
      <Link to={routeName}>
        <button>ACESSAR</button>
      </Link>
    </div>
  );
}

export default SorteioCard;
