import React, { useEffect, useState } from "react";
import Banner from "../../Assets/Banner.jpg";
import "./HomePage.css";
import ContatoPage from "./Contato/ContatoPage";
import Sobre from "./Sobre/Sobre";
import SorteioCard from "./Components/Cards/SorteioCard/SorteioCard";
import baseURL from "../../Contants/api_url.js";
import { CircularProgress } from "@mui/material";

import axios from "axios";

function HomePage() {
  const [sorteios, setSorteios] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function recuperarTodosSorteios() {
      try {
        const response = await axios.get(`${baseURL}/sorteio/get-sorteios`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        });

        setSorteios(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }

    recuperarTodosSorteios();
  }, []);

  return (
    <div className="home-page">
      <div className="home-container">
        <img className="banner-img" src={Banner} alt="" />
        <div className="recent-winners">
          {/* <h6>GANHADORES RECENTES</h6>
          <br />
          <WinnerCard /> */}
        </div>
        <div className="sorteios">
          <h6>
            {loading ? (
              <div className="loading-anim">
                <CircularProgress size={48} color="inherit" />
                <h6>CARREGANDO SORTEIOS</h6>
              </div>
            ) : sorteios.length > 0 ? (
              "SORTEIOS DISPONÍVEIS:"
            ) : (
              "NÃO HÁ SORTEIOS NO MOMENTO!"
            )}
          </h6>

          <div className="cards-sorteios">
            {sorteios
              .slice()
              .reverse()
              .map((sorteio) => (
                <SorteioCard
                  key={sorteio.id}
                  imageURL={sorteio.imageURL}
                  title={sorteio.titulo}
                  description={sorteio.descricao}
                  id={sorteio.id}
                />
              ))}
          </div>
        </div>
        <div className="info">
          <p>COMO PARTICIPAR?</p>
          <div className="info-texts">
            <div className="info-text">
              <h6>ESCOLHA O SORTEIO</h6>
              <br />
              <p>
                Escolha o sorteio que deseja participar, verifique a descrição,
                regulamento da opção e fotos. Se tiver alguma dúvida, entre em
                contato com o administrador.
              </p>
            </div>
            <div className="info-text">
              <h6>COMPRE SUAS RIFAS</h6>
              <br />
              <p>
                Você pode comprar quantas rifas desejar! Quanto mais rifas, mais
                chances de ganhar.
              </p>
            </div>
            <div className="info-text">
              <h6>AGUARDE O RESULTADO</h6>
              <br />
              <p>
                Aguarde o resultado do sorteio. Cruze os dedos! Você pode ser o
                próximo sorteado.
              </p>
            </div>
            <div className="info-text">
              <h6>ALGUMA DÚVIDA?</h6>
              <br />
              <p>Não hesite em perguntar, entre em contato conosco!</p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
      <ContatoPage />
      <Sobre />
    </div>
  );
}

export default HomePage;
