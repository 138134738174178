import ContactImage from "../../../Assets/contact.png";
import SendImage from "../../../Assets/Send.png";

import "./ContatoPage.css";

function abrirLinkWhatsapp() {
  var urlWhatsapp = "https://api.whatsapp.com/send?phone=5579996592595";
  window.open(urlWhatsapp, "_blank");
}

function ContatoPage() {
  return (
    <div className="contato-page">
      <div className="contato-info">
        <div className="title">
          <h1>CONTATO</h1>
        </div>

        <h3>Precisa de suporte?</h3>
        <h3>Encontrou algum problema?</h3>
        <p>
          Fale conosco! Estamos aqui para atender às suas perguntas, ouvir suas
          opiniões e tornar sua experiência melhor. Sinta-se à vontade para nos
          contatar a qualquer momento.
        </p>
        <div className="contato-btn">
          <p>Entre em contato agora via Whatsapp</p>
          <button onClick={() => abrirLinkWhatsapp()}>
            <img src={SendImage} alt="" width={20} />
            ENVIAR MENSAGEM
          </button>
        </div>
      </div>
      <img className="contato-imagem" src={ContactImage} alt="" width={400} />
    </div>
  );
}

export default ContatoPage;
