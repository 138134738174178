import React, { useState, useEffect } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { format } from "date-fns";
import "./AddDraw.css";
import { LuTrash } from "react-icons/lu";
import axios from "axios";
import baseURL from "../../Contants/api_url";
import { CircularProgress } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";

function AddDraw({ handleBackgroundClick, editMode, sorteioInfo }) {
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(null);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cotas, setCotas] = useState(0);
  const [min_cotas, setMin_Cotas] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [dataEncerramento, setDataEncerramento] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (editMode && sorteioInfo) {
      setImageURL(sorteioInfo.imageURL);
      setTitle(sorteioInfo.titulo || "");
      setDescription(sorteioInfo.descricao || "");
      setCotas(sorteioInfo.valorCota || 0);
      setMin_Cotas(sorteioInfo.min_cotas || 1);
      setDataEncerramento(sorteioInfo.dataEncerramento);
    }
  }, [editMode, sorteioInfo]);

  const handleImageUpload = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setImage(selectedImage);
    }
  };

  const handleCotasChange = (e) => {
    setCotas(e.target.value);
  };

  const handleMinCotasChange = (e) => {
    const newValue = Number(e.target.value);

    if (!isNaN(newValue) && newValue >= 0) {
      setMin_Cotas(newValue);
    } else {
      setMin_Cotas(1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!image && !editMode) {
        setLoading(false);
        return;
      }

      let imagemURL = imageURL;
      if (image) {
        setUploading(true);
        const storage = getStorage();

        const storageRef = ref(storage, "images/" + image.name);

        const snapshot = await uploadBytes(storageRef, image);

        imagemURL = await getDownloadURL(snapshot.ref);
      }
      setLoading(false);

      const newValue = parseFloat(cotas).toLocaleString("en-US");

      const dataEncerramentoDate = new Date(dataEncerramento);

      if (!isNaN(dataEncerramentoDate.getTime())) {
        const dataEncerramentoFormatted = format(
          dataEncerramentoDate,
          "yyyy-MM-dd'T'HH:mm:ss"
        );

        const values = {
          titulo: title,
          descricao: description,
          valorCota: newValue,
          imageURL: imagemURL,
          dataEncerramento: dataEncerramentoFormatted,
          min_cotas: min_cotas,
        };

        if (editMode) {
          await axios.put(
            `${baseURL}/sorteio/atualizar-sorteio/${sorteioInfo.id}`,
            values
          );
        } else {
          await axios.post(`${baseURL}/sorteio/inserir-sorteio`, values);
        }

        setLoading(false);
        setUploading(false);

        handleBackgroundClick();

        setImage(null);
        setTitle("");
        setDescription("");
        setCotas(0);
        setMin_Cotas(1);
        setDataEncerramento("");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      await axios.delete(
        `${baseURL}/sorteio/excluir-sorteio/${sorteioInfo.id}`
      );

      setShowDeleteModal(false);
      handleBackgroundClick();
    } catch (error) {
      console.error("Erro ao excluir sorteio:", error);
    }
  };

  const handleDeleteButtonClick = (e) => {
    e.preventDefault();
    setShowDeleteModal(true);
  };

  return (
    <div className="add-draw-container">
      <div className="top-card">
        <h1>{editMode ? "Editar Sorteio" : "Adicionar Sorteio"} </h1>
        <button onClick={handleBackgroundClick}>
          <IoCloseCircleOutline />
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <label>Escolher Imagem:</label>
        <input type="file" accept="image/*" onChange={handleImageUpload} />

        {uploading && <p>Enviando imagem... Aguarde</p>}

        <label>Título:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <label>Descrição:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <label>Valor das Cotas: (Ex: 0.50)</label>
        <input type="number" value={cotas} onChange={handleCotasChange} />
        <label>Quantidade mínima para compra</label>
        <input
          type="number"
          value={min_cotas}
          onChange={handleMinCotasChange}
        />
        <label>Data e Hora de Encerramento:</label>
        <input
          type="datetime-local"
          id="data-encerramento"
          onChange={(e) => setDataEncerramento(e.target.value)}
        />

        <button type="submit">
          {loading ? <CircularProgress /> : "SALVAR"}
        </button>
        {editMode ? (
          <div className="delete-btn">
            <button onClick={handleDeleteButtonClick}>
              <LuTrash />
              EXCLUIR SORTEIO
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </form>
      {showDeleteModal && (
        <div className="delete-modal">
          <p>Deseja realmente excluir este sorteio?</p>
          <div className="btns-delete-modal">
            <button
              style={{
                backgroundColor: "#393f4f",
                color: "#fff",
                fontWeight: "bold",
              }}
              className="confirm-btn"
              onClick={handleDeleteConfirmation}
            >
              Sim
            </button>
            <button
              style={{ backgroundColor: "#C4C5C9", color: "#000" }}
              className="cancel-btn"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddDraw;
