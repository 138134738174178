import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import RifaNumero from "../Home/SorteioPage/cotas/RifaNumero.js";
import baseURL from "../../Contants/api_url.js";
import Lottie from "lottie-react";
import notFoundAnim from "../../Assets/anim/not-found.json";
import "./MeusTitulos.css";

function MeusTitulos() {
  const [userRaffles, setUserRaffles] = useState([]);
  const [loading, setLoading] = useState(true);
  const userUID = localStorage.getItem("userUID");

  useEffect(() => {
    if (!userUID || loading) {
      setLoading(false);
      return;
    }

    axios
      .get(`${baseURL}/sorteio/get-sorteios-usuario/${userUID}`, {
        headers: {
          "ngrok-skip-browser-warning": "true",
        },
      })
      .then((response) => {
        setUserRaffles(response.data);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [userUID, loading]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: notFoundAnim,
  };
  return (
    <div className="meus-sorteios">
      {loading ? (
        <CircularProgress color="primary" />
      ) : userUID ? (
        <div className="title">
          {userRaffles && userRaffles.length > 0 ? (
            <h1>Meus Títulos</h1>
          ) : (
            <div></div>
          )}

          {userRaffles.map((sorteio) => (
            <div key={sorteio.id} className="card-user-sorteio">
              <Link to={`/sorteio/${sorteio.id}`}>
                <div className="sorteio-user-card">
                  <img src={sorteio.imageURL} alt={sorteio.titulo} />
                  <div className="card-sorteio-user-info">
                    <h6>{sorteio.titulo}</h6>
                  </div>
                </div>
              </Link>

              <div className="user-numbers">
                <p>Meus números:</p>
                <div className="grid-user-cotas">
                  {sorteio.numerosUsuario &&
                    Array.from(new Set(sorteio.numerosUsuario)).map(
                      (numero) => <RifaNumero key={numero} numero={numero} />
                    )}
                </div>
                <p>
                  Total:{" "}
                  {sorteio.numerosUsuario && sorteio.numerosUsuario.length}
                </p>
              </div>
            </div>
          ))}
          {userRaffles && userRaffles.length < 1 ? (
            <div className="notFoundAnim">
              <h1>Você ainda não está participando de nenhum sorteio</h1>
              <Lottie
                animationData={notFoundAnim}
                style={{ width: "200px", height: "200px" }}
              />

              <h1>
                Que tal garantir algumas cotas? Pode ser o seu dia de sorte!
              </h1>
              <Link to="/">
                <button>VER SORTEIOS</button>
              </Link>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <div className="user-not-logged-in">
          <h1>É NECESSÁRIO REALIZAR O LOGIN PARA ACESSAR ESTA ÁREA</h1>

          <Link to="/">
            <button>VOLTAR À PÁGINA INICIAL</button>
          </Link>
        </div>
      )}
    </div>
  );
}

export default MeusTitulos;
