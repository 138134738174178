import React, { useEffect, useState } from "react";
import { HashRouter as Router, Link, Route, Routes } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import HomePage from "./Pages/Home/HomePage";
import logo from "./Assets/WD_LOGO.png";
import ChevronUpOutline from "./Assets/chevron-up-outline.svg";
import Login from "./Services/Auth/Auth";
import SorteioPage from "./Pages/Home/SorteioPage/SorteioPage";
import "./App.css";
import styles from "./Navbar.module.css";
import MeusTitulos from "./Pages/UserPages/MeusTitulos";
import AddDraw from "./Services/FormSorteios/AddDraw";
import AdmHomePage from "./Pages/AdmPages/AdmHomePage";
import Logout from "./Services/Auth/Logout";
import { IoIosPerson, IoIosLogOut } from "react-icons/io";
import { IoBookOutline } from "react-icons/io5";
import Regulamento from "./Pages/Home/Regulamento";

function App() {
  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [user, setUser] = useState(null);

  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const handleOpenLogin = () => {
    setIsLoginOpen(true);
  };

  const handleCloseLogin = () => {
    setIsLoginOpen(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();
  };

  const removeActive = () => {
    setIsActive(false);
  };

  const toggleVisibility = () => {
    if (window.scrollY > 250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  }, [auth]);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav className={`${styles.navbar}`}>
            <p className={`${styles.logo}`}>
              <Link to="/" className="logo-link">
                <img src={logo} alt="Logo" height="40" />
              </Link>
            </p>
            <ul
              className={`${styles.navMenu} ${isActive ? styles.active : ""}`}
            >
              {user ? (
                <div className={styles.userMenu}>
                  <span>MENU</span>
                  <div className={styles.userMenuDropdown}>
                    <Link to="/meus-titulos">
                      <IoIosPerson />
                      Meus títulos
                    </Link>
                    <Link to="/regulamento">
                      <IoBookOutline />
                      Regulamento
                    </Link>
                    <Link to="/logout">
                      <IoIosLogOut />
                      Sair
                    </Link>
                  </div>
                </div>
              ) : (
                <li onClick={removeActive}>
                  <Link onClick={handleOpenLogin} className="login-button">
                    LOGIN / CRIAR CONTA
                  </Link>
                </li>
              )}
            </ul>
          </nav>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sorteio/:id" element={<SorteioPage />} />
            <Route path="/meus-titulos" element={<MeusTitulos />} />
            <Route path="/criar-sorteio" element={<AddDraw />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/adm" element={<AdmHomePage />} />
            <Route path="/regulamento" element={<Regulamento />} />
          </Routes>
        </header>

        {isVisible && (
          <button
            onClick={scrollToTop}
            className={`${styles.goTopBtn} fade-in`}
          >
            <img
              src={ChevronUpOutline}
              alt=""
              width={30}
              height={30}
              color="#FFF"
            />
          </button>
        )}

        {isLoginOpen && (
          <div className="login-popup-background" onClick={handleCloseLogin}>
            <div className="login-popup" onClick={handleFormClick}>
              <div className="popup-content">
                <Login handleCloseLogin={handleCloseLogin} />
              </div>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
